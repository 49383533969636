import {Component} from '@angular/core';
import {PlatformMediaCustomableName} from '../../../../../shared/db-models/media';

@Component({
  selector: 'app-cms',
  template: `<div class="container-lg">
    <div class="row">
      <div class="col-12">
        <section class="settings-page">
          <!-- Page cover -->
          <div class="page-cover page-cover-user">
            <h1 class="welcome-text">Media Files</h1>
          </div>
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Files</a>
              <ng-template ngbNavContent
                ><app-media-file-manager
                  *ngFor="let name of filesNames"
                  [platformMediaCustomableName]="name"
                ></app-media-file-manager
              ></ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Text properties</a>
              <ng-template ngbNavContent
                ><app-platform-text-properties-form></app-platform-text-properties-form
              ></ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Legal content</a>
              <ng-template ngbNavContent>
                <app-legal-content-manager></app-legal-content-manager>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </section>
      </div>
    </div>
  </div>`,
})
export class CmsComponent {
  public activeId: number = 1;
  public filesNames = Object.values(PlatformMediaCustomableName) as PlatformMediaCustomableName[];
}
