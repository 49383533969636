import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {from, Observable, Subject} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {GoogleAuthProvider, User} from '@angular/fire/auth';
import {ConnectedUserModel} from '../interfaces/users-models';
import {DbUserModel} from '../../../../shared/db-models/user';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteUserModalComponent} from '../modals/delete-user-modal/delete-user-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private connectedUser: ConnectedUserModel | null;
  private connectedUserRequested: boolean;
  private connectedUserLoaded: boolean;
  public connectedUserChanged: Subject<ConnectedUserModel | null> =
    new Subject<ConnectedUserModel | null>();

  constructor(
    private afAuth: AngularFireAuth,
    public modalService: NgbModal,
    private fns: AngularFireFunctions
  ) {}

  /**
   * return active user from local
   */
  public getConnectedUserSync(): ConnectedUserModel | null {
    return this.connectedUser;
  }

  /**
   * retruns active user from db
   * @param force
   */
  public getConnectedUser(force: boolean = false): Observable<ConnectedUserModel | null> {
    const result = new Subject<ConnectedUserModel | null>();

    if (!this.connectedUserRequested || force) {
      this.connectedUserRequested = true;
      this.connectedUserLoaded = false;

      this.afAuth.user.pipe(take(1)).subscribe(
        async (user) => {
          if (!user) {
            this.connectedUser = null;
            result.next(this.connectedUser);
            result.complete();
          } else {
            // User is only valid once phone number is set
            if (!user.phoneNumber) {
              result.error('User has no phone number');
              this.signOut();
              result.complete();
              return;
            }

            // Check if admin
            const isAdmin = await this.isUserAdmin(user);
            if (isAdmin) {
              this.updateConnectedUser(user);
              result.next(this.connectedUser);
              this.connectedUserLoaded = true;

              result.complete();
            } else {
              result.error('User is not an admin');
              this.signOut();
              result.complete();
            }
          }
        },
        (error) => {
          result.error(error);
          result.complete();
        }
      );
    } else {
      const connectedUserInterval = setInterval(() => {
        if (this.connectedUserLoaded) {
          clearInterval(connectedUserInterval);
          result.next(this.connectedUser);
          result.complete();
        }
      }, 100);
    }

    return result;
  }

  public reloadConnectedUserData(): void {
    this.afAuth.user.pipe(take(1)).subscribe((user) => {
      if (user != null) {
        user.reload().then(() => {
          this.getConnectedUser(true).subscribe(() => {});
        });
      }
    });
  }

  /**
   * Start sign up with a specific provider and returns user credential
   * @param prov
   */
  public async signinWithProvider(prov: string) {
    if ('google' !== prov.toLowerCase()) throw new Error('Provider not supported');
    const googleAuthProvider = new GoogleAuthProvider();
    googleAuthProvider.addScope('email');
    googleAuthProvider.addScope('phone');
    return await this.afAuth.signInWithPopup(googleAuthProvider).catch((error) => {
      console.error('error in sign in with provider', error);
      throw error;
    });
  }

  public signInWithEmail(email: string, password: string) {
    return from(this.afAuth.signInWithEmailAndPassword(email, password));
  }

  /**
   * Signout connected user
   */
  public async signOut(): Promise<void> {
    return this.afAuth.signOut();
  }

  /**
   * Get all super admins in the system
   */
  public getAllSuperAdmins(): Observable<DbUserModel[]> {
    return this.fns.httpsCallable('getAllSuperAdmins')({});
  }

  /**
   * Get all super admins in the system
   */
  public getAllSiteOwners(): Observable<DbUserModel[]> {
    return this.fns.httpsCallable('getAllSiteOwners')({});
  }

  /**
   * Set a user as a super admin
   */
  public setUserAsSuperAdmin(email: string) {
    return this.fns.httpsCallable('setUserAsSuperAdmin')({email});
  }

  public createUserAsSuperAdmin(email: string, phoneNumber: string) {
    return this.fns.httpsCallable('createUserAsSuperAdmin')({email, phoneNumber});
  }

  /**
   * Remove the super admin role from a user
   */
  public removeUserAsSuperAdmin(uid: string) {
    return this.fns.httpsCallable('removeUserAsSuperAdmin')({uid});
  }

  // **************************************************************** //
  // *************************** Local ****************************** //
  // **************************************************************** //
  public async isUserAdmin(user: Pick<User, 'getIdTokenResult'>): Promise<boolean> {
    const idTokenResult = await user.getIdTokenResult(true);
    return idTokenResult.claims?.admin === true;
  }

  /**
   * Update the current active user
   * @param firebaseUser
   */
  public updateConnectedUser(
    firebaseUser: Pick<User, 'displayName' | 'uid' | 'email' | 'photoURL' | 'phoneNumber'> | null
  ): ConnectedUserModel | null {
    this.connectedUser = firebaseUser
      ? ({
          uid: firebaseUser.uid,
          displayName: firebaseUser.displayName ? firebaseUser.displayName : '',
          email: firebaseUser.email ? firebaseUser.email : '',
          phone: firebaseUser.phoneNumber,
          photoURL: firebaseUser.photoURL ? firebaseUser.photoURL : '',
        } as ConnectedUserModel)
      : null;

    return this.connectedUser;
  }

  public deleteUser(userModel: DeleteUserModalComponent['userModel']): Observable<string> {
    return this.fns.httpsCallable('deleteUser')({id: userModel!.uid});
  }
  public async deleteUsersByDomain(domain: string) {
    const template: string =
      'Delete Result \n\n\n' +
      JSON.stringify(
        await this.fns
          .httpsCallable('deleteUsersByDomain')({domain})
          .toPromise()
          .catch(() => {
            return this.deleteUsersByDomain(domain);
          })
      );

    return this.modalService.open(template);
  }
  public getUserModel(userIdentifier: string): Observable<DeleteUserModalComponent['userModel']> {
    return this.fns.httpsCallable('getUserModel')({userIdentifier});
  }
}
