import {Component, inject} from '@angular/core';
import {UsersService} from 'src/app/services/users.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {DeleteUserModalComponent} from 'src/app/modals/delete-user-modal/delete-user-modal.component';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent {
  userModel: DeleteUserModalComponent['userModel'] | undefined;
  public usersService: UsersService = inject(UsersService);
  public modalService: NgbModal = inject(NgbModal);
  public toastr: ToastrService = inject(ToastrService);

  deleteUserModel = false;

  public async findUser(identifier: string) {
    this.usersService.getUserModel(identifier).subscribe(
      (response: DeleteUserModalComponent['userModel']) => {
        if (response) {
          this.userModel = response;
          const modal = this.modalService.open(DeleteUserModalComponent, {
            size: 'xl',
            backdrop: 'static',
            windowClass: 'dark-modal ltr',
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
          });
          (modal.componentInstance as DeleteUserModalComponent).userModel = this.userModel;
        }
      },
      (err) => {
        this.toastr.error('User not found', err);
      }
    );
  }
}
