import {Component, inject} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {LegalContentSettings} from '../../../../../../shared/db-models/media';
import {defaultLegalContentItems} from '../../../../../../shared/defaultLegalContentItems';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe-decorator';

@Component({
  selector: 'app-legal-content-manager',
  template: `
    <h1>Legal content manager</h1>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <label>
        <input type="checkbox" formControlName="overridden" />
        Override
      </label>

      <div *ngIf="form.get('overridden')?.value">
        <a href="https://cloudconvert.com/pdf-to-html" target="_blank">Convert PDF to HTML</a>
        <div formArrayName="items">
          <div *ngFor="let item of items.controls; let idx = index">
            <div [formGroupName]="idx">
              <div class="row">
                <input placeholder="Title" formControlName="title" />
              </div>
              <div class="row">
                <textarea placeholder="HTML Content" formControlName="htmlContent"></textarea>
              </div>
              <button type="button" (click)="deleteItem(idx)">Delete</button>
              <button type="button" (click)="moveUp(idx)">Move Up</button>
              <button type="button" (click)="moveDown(idx)">Move Down</button>
            </div>
          </div>
        </div>
        <button type="button" (click)="addItem()">Add Item</button>
      </div>

      <button type="submit">Save</button>
    </form>
  `,
})
export class LegalContentManagerComponent {
  form: FormGroup;

  private fb = inject(FormBuilder);
  private db = inject(AngularFirestore);

  @AutoUnsubscribe()
  dataSubscription$: Subscription;

  constructor() {
    this.form = this.fb.group({
      overridden: false,
      items: this.fb.array([]),
    });

    // Loop through default items and populate the 'items' FormArray
    defaultLegalContentItems.forEach((item) => {
      const itemGroup = this.fb.group({
        title: [item.title],
        htmlContent: [item.htmlContent],
      });
      this.items.push(itemGroup);
    });

    this.dataSubscription$ = this.db
      .doc<LegalContentSettings>('media/legalContent')
      .valueChanges()
      .subscribe((data) => {
        if (data) {
          this.form.patchValue({
            overridden: data.overridden,
          });

          this.items.clear();

          data.items.forEach((item) => {
            const itemGroup = this.fb.group({
              title: item.title,
              htmlContent: item.htmlContent,
            });
            this.items.push(itemGroup);
          });
        }
      });
  }

  get items() {
    return this.form.get('items') as FormArray;
  }

  deleteItem(index: number) {
    if (confirm('Are you sure you want to remove this item?')) {
      this.items.removeAt(index);
    }
  }

  moveUp(index: number) {
    if (index === 0) return;
    const item = this.items.at(index);
    this.items.removeAt(index);
    this.items.insert(index - 1, item);
  }

  moveDown(index: number) {
    if (index === this.items.length - 1) return;
    const item = this.items.at(index);
    this.items.removeAt(index);
    this.items.insert(index + 1, item);
  }

  addItem() {
    const item = this.fb.group({
      title: '',
      htmlContent: '',
    });

    this.items.push(item);
  }

  onSubmit() {
    const legalContentSettings: LegalContentSettings = this.form.value;
    this.db.doc('media/legalContent').set({...legalContentSettings});
  }
}
