import {UntypedFormGroup, NgForm} from '@angular/forms';

export default class AdminHelpers {
  public static markFormInvalidAndFocus(f: NgForm): void {
    // Jump to fist invalid element and mark all controls as touched
    let elementToFocus: any;
    for (const currControlName of Object.keys(f.controls)) {
      const currControl = f.controls[currControlName];
      currControl.markAsTouched();

      if (!elementToFocus && currControl.invalid) {
        elementToFocus = document.getElementsByName(currControlName)[0];

        if (!elementToFocus) {
          elementToFocus = document.getElementById(currControlName);
        }
      }

      if (currControl instanceof UntypedFormGroup) {
        AdminHelpers.markFormGroupInvalidAndFocus(currControl as UntypedFormGroup);
      }
    }

    if (elementToFocus) {
      elementToFocus.scrollIntoView();
      elementToFocus.focus();
    }
  }

  public static markFormGroupInvalidAndFocus(g: UntypedFormGroup): void {
    // Jump to fist invalid element and mark all controls as touched
    let elementToFocus: any;
    for (const currControlName of Object.keys(g.controls)) {
      const currControl = g.controls[currControlName];
      currControl.markAsTouched();

      if (!elementToFocus && currControl.invalid) {
        elementToFocus = document.getElementsByName(currControlName)[0];

        if (!elementToFocus) {
          elementToFocus = document.getElementById(currControlName);
        }
      }
    }

    if (elementToFocus) {
      elementToFocus.scrollIntoView();
      elementToFocus.focus();
    }
  }
}
