import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {
  PlatformTextProperties,
  PlatformTextPropertiesName,
} from '../../../../../../shared/db-models/media';
import {Timestamp} from '@angular/fire/firestore';

@Component({
  selector: 'app-platform-text-properties-form',
  template: `
    <h1>text properties</h1>
    toggle to change the value; when unchecked = value will be the default of the clients<br />

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div *ngFor="let property of properties">
        <label> <input type="checkbox" [formControlName]="property" /> {{ property }} </label>
        <div *ngIf="form.get(property)?.value">
          <input type="text" [formControlName]="property + '-value'" />
        </div>
      </div>
      <button type="submit">Save</button>
    </form>
  `,
})
export class PlatformTextPropertiesFormComponent {
  form: FormGroup;
  properties = Object.values(PlatformTextPropertiesName);
  constructor(private fb: FormBuilder, private db: AngularFirestore) {
    this.form = this.fb.group({});
    this.properties.forEach((property) => {
      this.form.addControl(property, this.fb.control(false));
      this.form.addControl(property + '-value', this.fb.control(''));
    });
    this.db
      .doc<PlatformTextProperties>('media/textProperties')
      .valueChanges()
      .subscribe((data) => {
        if (data) {
          Object.keys(data).forEach((property) => {
            this.form.controls[property].setValue(data[property].value);
            this.form.controls[property + '-value'].setValue(data[property].value);
          });
        }
      });
  }
  submit() {
    const data = this.form.value;
    const platformTextProperties = {} as PlatformTextProperties;
    this.properties.forEach((property) => {
      if (data[property]) {
        platformTextProperties[property] = {
          key: property,
          value: data[property + '-value'],
          uploadedAt: Timestamp.now(),
        };
      }
    });
    this.db.doc('media/textProperties').set({...platformTextProperties});
  }
}
