import {DbProductModel} from '../../db-models/product';
import {ConvertProductsDto} from '../../utilities/integration';
import {fixPrice} from './utils';

type PriceshoesProduct = {
  'PRODUCT ID': string;
  SKU: string;
  NOMBRE: string;
  COLOR: string;
  TALLA: string;
  'PRECIO SOCIA': string;
  'LINK ID': string;
  'LINK IMAGEN': string;
};
export function priceShoesCSVTToTerrificProductStrategy(
  productRows: PriceshoesProduct[],
  storeId: string
) {
  const fixName = (product: DbProductModel) => {
    product.name = `${product.name} - ${
      product.options.find((o) => o.option === 'Color')?.values[0]
    }`;
  };

  // the last to digits are the variant number, and the link is broken when it is included
  productRows.forEach((product) => {
    product['LINK ID'] = product['LINK ID'].slice(0, -2);
  });

  const dto: ConvertProductsDto<PriceshoesProduct> = {
    externalProductIdFn: (product) => `${product['PRODUCT ID']}${product['COLOR']}`,
    externalVariantIdFn: (product) => product['SKU'],
    productRows,
    productTransformer: {
      'name': 'NOMBRE',
      'price': 'PRECIO SOCIA',
      'imageUrls[]': 'LINK IMAGEN',
      externalUrl: 'LINK ID',
      'sku': 'PRODUCT ID',
    },
    storeId,
    variantTransformer: {
      'shortDescription': 'NOMBRE',
      'optionValues.Color': 'COLOR',
      'optionValues.Talla': 'TALLA',
      'price': 'PRECIO SOCIA',
      'externalUrl': 'LINK ID',
      'imageUrls[]': 'LINK IMAGEN',
      'sku': 'SKU',
    },
    postTransformOfProductFn: (product) => {
      fixName(product);
      product.price = fixPrice(product.price);
      return product;
    },
    postTransformOfVariantFn: (variant) => {
      variant.price = fixPrice(variant.price);
      return variant;
    },
  };

  return dto;
}
