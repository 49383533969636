<!-- Header -->
<header class="fixed-top">
  <div class="header-col">
    <button
      type="button"
      class="sidebar-toggler"
      (click)="toggleSidebar()"
      [ngClass]="{'open': showSidebar}"
    >
      <span class="toggler-icon"></span>
    </button>
  </div>
  <div class="header-col text-center"></div>
</header>

<!-- Sidebar -->
<nav class="sidebar" [ngClass]="{'open': showSidebar}">
  <swiper [config]="swiperConfigSidebar" class="swiper-container">
    <ng-template swiperSlide>
      <div class="user-details">
        <div *ngIf="connectedUser?.photoURL" class="avatar">
          <img
            class="avatar-image"
            src="{{ connectedUser?.photoURL ?? 'assets/images/avatar-placeholder.svg' }}"
            alt=""
          />
        </div>
        <div class="text">
          <p class="welcome-text">Welcome</p>
          <p class="username">{{ connectedUser?.displayName }}</p>
        </div>
        <div class="logout">
          <button type="button" class="btn btn-link text-danger" (click)="logout()">Logout</button>
        </div>
      </div>

      <!--  Menu Items  -->
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faHome"></fa-icon>
            Home
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/currencies']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faDollarSign"></fa-icon>
            Currencies
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/stores']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faTh"></fa-icon>
            Stores
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adminUsers']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faUsersCog"></fa-icon>
            Admin Users
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/deleteUser']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faTrash"></fa-icon>
            Delete Users
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/SmsCreation']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faSms"></fa-icon>
            Sms Creation
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/media']" [routerLinkActive]="'active'">
            <fa-icon [icon]="faFileUpload"></fa-icon>
            Media files
          </a>
        </li>
      </ul>
    </ng-template>
    <!-- Add Scroll Bar -->
    <div class="swiper-scrollbar"></div>
  </swiper>
</nav>

<!-- Main -->
<main role="main">
  <router-outlet></router-outlet>
</main>

<!-- Footer -->
<footer>
  <div class="container-lg">
    <div class="row">
      <div class="col-12">
        <div class="footer-lower">
          <div class="legal">
            <p>© All rights reserved Terrific {{ currentYear }}</p>
          </div>
          <div class="menu">
            <ul class="list-unstyled">
              <li><a class="logout" (click)="logout()">Logout</a></li>
              <li><a [routerLink]="['/stores']">Stores</a></li>
              <li><a [routerLink]="['/adminUsers']">Admin Users</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
