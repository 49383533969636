import {AuthPipe, canActivate, customClaims} from '@angular/fire/compat/auth-guard';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {LoginComponent} from './pages/login/login.component';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {HomeComponent} from './pages/home/home.component';
import {AdminUsersComponent} from './pages/admin-users/admin-users.component';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {StoresComponent} from './pages/stores/stores.component';
import {CurrenciesComponent} from './pages/currencies/currencies.component';
import {DeleteUserComponent} from './pages/delete-user/delete-user.component';
import {SmsCreationComponent} from './pages/sms-creation/sms-creation.component';
import {CmsComponent} from './pages/cms/cms.component';

export const hasCustomClaimOrRedirect = (claim: string, redirect: string | string[]) =>
  pipe(
    customClaims as AuthPipe,
    map((claims) => (Object.prototype.hasOwnProperty.call(claims, claim) ? true : redirect))
  );

const adminOnly = () => hasCustomClaimOrRedirect('admin', ['login']);

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    ...canActivate(adminOnly),
    children: [
      {path: '', redirectTo: '/home', pathMatch: 'full'},
      {path: 'home', component: HomeComponent},
      {path: 'currencies', component: CurrenciesComponent},
      {path: 'adminUsers', component: AdminUsersComponent},
      {path: 'stores', component: StoresComponent},
      {path: 'deleteUser', component: DeleteUserComponent},
      {path: 'SmsCreation', component: SmsCreationComponent},
      {path: 'media', component: CmsComponent},
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {path: '', redirectTo: '/login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
