<div class="delete-container">
  <div class="delete-option">
    <h1>Delete User</h1>
    <label for="email">Email or Phone</label>
    <input
      type="search"
      class="form-control"
      placeholder="Email/Phone"
      autocomplete="off"
      email
      required
      #userInfo
    />

    <button class="btn btn-yellow btn-sm findUser" (click)="findUser(userInfo.value)">
      Find User
    </button>
  </div>
  <div class="delete-option">
    <h1>Delete Users</h1>
    <label for="domain">Domain of the users</label>
    <input
      aria-label="domain to delete users"
      #domain
      type="url"
      class="form-control"
      placeholder="example.com"
    />
    <button
      class="btn btn-yellow btn-sm findUser"
      (click)="this.usersService.deleteUsersByDomain(domain.value)"
    >
      delete all users with email domain
    </button>
  </div>
</div>
