import {DbProductModel} from '../../db-models/product';
import {ConvertProductsDto} from '../../utilities/integration';
import {fixPrice} from './utils';

export type TerrificProductRow = Partial<
  Pick<
    DbProductModel,
    'name' | 'price' | 'barcode' | 'compareAtPrice' | 'sku' | 'description' | 'id'
  > & {
    url: string;
    stock: number;
    color: string;
    size: string;
    material: string;
    toSplitProductBySize: boolean;
    toSplitProductByColor: boolean;
    toSplitProductByMaterial: boolean;
    image: string;
    additionalImages: `${string},${string},${string},${string},${string}`;
  }
>;

export function terrificCSVTToTerrificProductStrategy(
  productRows: TerrificProductRow[],
  storeId: string
) {
  const fixName = (product: TerrificProductRow) => {
    product.name = product.name?.trim() ?? '';
    if (product.toSplitProductByColor) {
      product.name = `${product.name.trim()} - ${product.color?.trim()}`;
    }
    if (product.toSplitProductByMaterial) {
      product.name = `${product.name.trim()} - ${product.material?.trim()}`;
    }
    if (product.toSplitProductBySize) {
      product.name = `${product.name.trim()} - ${product.size?.trim()}`;
    }
  };

  const externalProductIdFn = (product: TerrificProductRow): string => {
    let id = product.id;
    if (product.toSplitProductByColor) {
      id = `${id}${product.color}`;
    }
    if (product.toSplitProductByMaterial) {
      id = `${id}${product.material}`;
    }
    if (product.toSplitProductBySize) {
      id = `${id}${product.size}`;
    }
    id = `${id}${product.barcode}`;
    return id;
  };

  const mergeImages = (product: TerrificProductRow) => {
    const images = product.additionalImages?.split(/,|\s/g) ?? [];
    product.image && images.unshift(product.image);
    return `[${images.join(',').replace(/\[\]/g, '')}]`; // this will be json parsed
  };

  productRows.forEach((product) => {
    product.image = mergeImages(product);
    fixName(product);
  });

  const dto: ConvertProductsDto<TerrificProductRow> = {
    externalProductIdFn: externalProductIdFn,
    externalVariantIdFn: (product) =>
      `${product.id}${product.sku}${product.barcode}${product.color}${product.size}${product.material}`,
    productRows,
    productTransformer: {
      'name': 'name',
      'price': 'price',
      'imageUrls[]': 'image',
      externalUrl: 'url',
      'sku': 'sku',
      'barcode': 'barcode',
      'description': 'description',
      'compareAtPrice': 'compareAtPrice',
    },
    storeId,
    variantTransformer: {
      'shortDescription': 'description',
      'optionValues.color': 'color',
      'optionValues.size': 'size',
      'optionValues.material': 'material',
      'price': 'price',
      'externalUrl': 'url',
      'imageUrls[]': 'image',
      'sku': 'sku',
      compareAtPrice: 'compareAtPrice',
      'liveData.stock': 'stock',
    },
    postTransformOfProductFn: (product) => {
      product.price = fixPrice(product.price);
      return product;
    },
    postTransformOfVariantFn: (variant) => {
      variant.price = fixPrice(variant.price);
      return variant;
    },
  };

  return dto;
}
