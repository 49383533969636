import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cron-exepression-edit-modal',
  templateUrl: './cron-expression-edit-modal.component.html',
  styleUrls: ['./cron-expression-edit-modal.component.scss'],
})
export class CronExpressionEditModalComponent {
  @Input() storeCronExpression: string;
  public value = '0 */12 * * *';

  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {
    if (this.storeCronExpression && this.storeCronExpression !== 'None') {
      this.value = this.storeCronExpression;
    }
  }

  public confirm(): void {
    this.modal.close(this.value);
  }

  public close(): void {
    this.modal.close(null);
  }

  public disableAutomation(): void {
    this.modal.close('None');
  }
}
