<!-- Sub header -->
<nav class="sub-header" *ngIf="settings.allowGalleryMode && settings.allowTableMode">
  <!-- View mode buttons -->
  <div class="container-lg">
    <div class="d-flex justify-content-center justify-content-lg-end">
      <button
        type="button"
        class="btn btn-link btn-view-mode p-0 mr-2"
        (click)="goToGallery()"
        [ngClass]="isGalleryMode ? 'active' : ''"
      >
        <img src="assets/images/boxes-view-icon.svg" alt="Boxes view" />
        <img class="active" src="assets/images/boxes-view-icon-yellow.svg" alt="Boxes view" />
      </button>
      <button
        type="button"
        class="btn btn-link btn-view-mode p-0"
        (click)="goToTable()"
        [ngClass]="!isGalleryMode ? 'active' : ''"
      >
        <img src="assets/images/list-view-icon.svg" alt="List view" />
        <img class="active" src="assets/images/list-view-icon-yellow.svg" alt="List view" />
      </button>
    </div>
  </div>
</nav>

<div class="row">
  <div class="col-12">
    <section class="category-gallery-page pt-4">
      <!-- Search -->
      <div class="search-bar" *ngIf="!this.settings.hideSearch">
        <form name="searchForm" #searchForm="ngForm">
          <div class="form-group has-search">
            <img
              class="form-control-feedback"
              src="assets/images/search-icon.svg"
              alt="Search icon"
            />
            <input
              type="text"
              class="form-control"
              id="page-search-bar"
              name="page-search-bar"
              #searchInput
              placeholder="{{ this.settings.searchPlaceholder }}"
              [(ngModel)]="searchTerm"
            />
          </div>
        </form>
      </div>
      <!-- Loader -->
      <ng-container *ngIf="isLoading">
        <app-loader></app-loader>
      </ng-container>

      <ng-container *ngIf="!isLoading">
        <!-- Grid view -->
        <ng-container *ngIf="isGalleryMode">
          <div class="row">
            <!-- Create new -->
            <div class="col-6 col-lg-3" *ngIf="settings.allowCreate">
              <a class="text-decoration-none" (click)="createItem()">
                <div class="gallery-item gallery-item-create-new">
                  <div class="image-holder">
                    <img
                      class="image"
                      src="{{
                        settings.gallerySettings?.createButtonImage
                          ? settings.gallerySettings?.createButtonImage
                          : 'assets/images/placeholder-category.png'
                      }}"
                      alt="Create new"
                    />
                    <!-- addon for create new -->
                    <div class="create-new">
                      <p>{{ settings.createButtonText ? settings.createButtonText : 'New' }}</p>
                    </div>
                  </div>
                  <div class="title-holder"></div>
                </div>
              </a>
            </div>

            <div class="col-6 col-lg-3" *ngFor="let currDataItem of data">
              <ng-template #galleryItem>
                <div class="gallery-item">
                  <div class="image-holder">
                    <img
                      class="image"
                      src="assets/images/placeholder-category.png"
                      alt="Category image"
                    />
                    <button
                      type="button"
                      class="edit-btn"
                      *ngIf="settings.allowEdit"
                      (click)="editItem(currDataItem)"
                    >
                      <img
                        class="edit-icon"
                        src="assets/images/edit-icon-dark.svg"
                        alt="Edit icon"
                      />
                    </button>
                  </div>
                  <div class="title-holder">
                    <p class="title">{{ currDataItem[settings.gallerySettings!.nameProperty] }}</p>
                  </div>
                </div>
              </ng-template>

              <a
                class="text-decoration-none"
                *ngIf="settings.gallerySettings?.linkFunc"
                [routerLink]="getItemGalleryLink(currDataItem)"
              >
                <ng-container *ngTemplateOutlet="galleryItem"></ng-container>
              </a>

              <a class="text-decoration-none" *ngIf="!settings.gallerySettings?.linkFunc">
                <ng-container *ngTemplateOutlet="galleryItem"></ng-container>
              </a>
            </div>
          </div>
        </ng-container>

        <!-- Table view -->
        <ng-container *ngIf="!isGalleryMode">
          <!-- Actions -->
          <div class="actions-bar my-3">
            <button
              type="button"
              class="btn btn-yellow btn-sm mr-2"
              *ngIf="settings.allowCreate"
              (click)="createItem()"
            >
              {{ settings.createButtonText }}
            </button>

            <!-- Custom Actions -->
            <ng-template *ngTemplateOutlet="actionsContentTpl?.templateRef ?? null"> </ng-template>
          </div>

          <!-- Table -->
          <div class="table-responsive">
            <table class="table table-style-3 table-branches">
              <thead>
                <tr>
                  <th *ngIf="settings.showActiveState">פעיל</th>
                  <!-- clases .asc and .desc are for example they will be added automaticly when table will be sortable -->
                  <th
                    class="sortable"
                    *ngFor="let currColumn of settings.tableSettings?.columns"
                    (click)="sortByColumn(currColumn)"
                    [ngClass]="{
                      'asc': currColumn.name === sortColumn && !sortDescending,
                      'desc': currColumn.name === sortColumn && sortDescending
                    }"
                  >
                    {{ currColumn.displayText }}
                  </th>
                  <th *ngIf="settings.allowEdit || itemActionsContentTpl">
                    <!-- edit -->
                  </th>
                  <th *ngIf="settings.tableSettings?.showExpand">
                    <!-- expand -->
                  </th>
                </tr>
              </thead>

              <tbody>
                <!-- Items -->
                <ng-container *ngFor="let currDataItem of data; let i = index">
                  <!-- Row -->
                  <tr [ngClass]="{'inactive': settings.showActiveState && !currDataItem['active']}">
                    <td *ngIf="settings.showActiveState">
                      <span
                        class="circle"
                        [ngClass]="{
                          'active': currDataItem['active'],
                          'inactive': !currDataItem['active']
                        }"
                      ></span>
                    </td>
                    <td *ngFor="let currColumn of settings.tableSettings?.columns">
                      <ng-template #textContent>
                        <ng-container *ngIf="currColumn.parser; else noParser">
                          {{ currColumn.parser(currDataItem) }}
                        </ng-container>
                        <ng-template #noParser>
                          <ng-container [ngSwitch]="currColumn.columnType">
                            <ng-container *ngSwitchCase="ListViewTableColumnType.DateTime">
                              {{
                                formatDateTime(currDataItem[currColumn.name], currColumn)
                              }}</ng-container
                            >
                            <ng-container *ngSwitchCase="ListViewTableColumnType.Date">
                              {{
                                formatDate(currDataItem[currColumn.name], currColumn)
                              }}</ng-container
                            >
                            <ng-container *ngSwitchCase="ListViewTableColumnType.Boolean">
                              {{ formatBoolean(currDataItem[currColumn.name]) }}</ng-container
                            >
                            <ng-container *ngSwitchCase="ListViewTableColumnType.Image">
                              <img
                                *ngIf="
                                  currDataItem[currColumn.name] &&
                                  currDataItem[currColumn.name].path
                                "
                                [src]="currDataItem[currColumn.name].path"
                                [height]="60"
                              />
                            </ng-container>
                            <ng-container *ngSwitchDefault>{{
                              formatDefaultText(currDataItem[currColumn.name], currColumn)
                            }}</ng-container>
                          </ng-container>
                        </ng-template>
                      </ng-template>

                      <a
                        *ngIf="currColumn.linkFunc"
                        [routerLink]="getItemColumnLink(currDataItem, currColumn)"
                      >
                        <ng-container *ngTemplateOutlet="textContent"></ng-container>
                      </a>

                      <ng-container *ngIf="!currColumn.linkFunc">
                        <ng-container *ngTemplateOutlet="textContent"></ng-container>
                      </ng-container>
                    </td>
                    <td
                      class="d-flex justify-content-end"
                      *ngIf="settings.allowEdit || itemActionsContentTpl"
                    >
                      @if(settings.allowEdit) {
                      <button type="button" class="btn btn-link p-0">
                        @if(this.storesIdsThatHasIntegration.includes(currDataItem.id)) {
                        <img
                          src="assets/images/reload-icon.svg"
                          alt="Sync Catalog"
                          placement="top"
                          [ngbTooltip]="getSyncIconTooltip(currDataItem)"
                          tooltipClass="tooltipWithLineBreaks"
                          (click)="syncIntergrationData(currDataItem)"
                        />
                        } @else {
                        <div style="width: 35px"></div>
                        }
                      </button>
                      }

                      <button type="button" class="btn btn-link p-0" *ngIf="settings.allowEdit">
                        <img
                          src="assets/images/edit-icon-light.svg"
                          alt="Edit btn"
                          (click)="editItem(currDataItem)"
                        />
                      </button>

                      <ng-template
                        *ngTemplateOutlet="
                          itemActionsContentTpl?.templateRef ?? null;
                          context: {item: currDataItem, index: i}
                        "
                      >
                      </ng-template>
                    </td>
                    <td
                      class="td-expand"
                      *ngIf="settings.tableSettings?.showExpand"
                      (click)="expandItem(currDataItem)"
                    >
                      <span class="text">More details</span>
                      <span
                        class="collapse-icon d-inline-block ml-lg-3"
                        [attr.expanded]="currDataItem.expanded"
                      ></span>
                    </td>
                  </tr>
                  <!-- Row collapse -->
                  <tr
                    *ngIf="settings.tableSettings?.showExpand"
                    [ngbCollapse]="!currDataItem.expanded"
                    class="tr-collapse"
                  >
                    <td *ngIf="currDataItem.loaded" [attr.colspan]="getExpandColspan()">
                      <ng-template
                        *ngTemplateOutlet="
                          expandContentTpl?.templateRef ?? null;
                          context: {item: currDataItem, index: i}
                        "
                      >
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </ng-container>

      <!--      <nav aria-label="Table pagination">-->
      <!--        <ul class="pagination justify-content-center mt-3">-->
      <!--          <li class="page-item">-->
      <!--            <button type="button" class="page-link" aria-label="First" (click)="goToPage(0)">-->
      <!--              <span aria-hidden="true">&laquo;&laquo;</span>-->
      <!--            </button>-->
      <!--          </li>-->
      <!--          <li class="page-item">-->
      <!--            <button type="button" class="page-link" aria-label="Previous" [disabled]="pageIndex === 0" (click)="prevPage()">-->
      <!--              <span aria-hidden="true">&laquo;</span>-->
      <!--            </button>-->
      <!--          </li>-->
      <!--          <li class="page-item" *ngFor="let currPageIndex of pages" [ngClass]="{'active': currPageIndex === pageIndex}">-->
      <!--            <button type="button" class="page-link" (click)="goToPage(currPageIndex)">{{currPageIndex + 1}}</button>-->
      <!--          </li>-->
      <!--          <li class="page-item">-->
      <!--            <button type="button" class="page-link" aria-label="Next" [disabled]="pageIndex >= (pageCount - 1)" (click)="nextPage()">-->
      <!--              <span aria-hidden="true">&raquo;</span>-->
      <!--            </button>-->
      <!--          </li>-->
      <!--          <li class="page-item">-->
      <!--            <button type="button" class="page-link" aria-label="Last" (click)="goToPage(pageCount - 1)">-->
      <!--              <span aria-hidden="true">&raquo;&raquo;</span>-->
      <!--            </button>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </nav>-->
    </section>
  </div>
</div>
