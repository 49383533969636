import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StoresService} from '../../services/stores.service';
import {ToastrService} from 'ngx-toastr';
import {NgForm} from '@angular/forms';
import TwoRaffleHelpers from '../../helpers/helpers';
import {finalize} from 'rxjs/operators';
import {DbCurrencyModel, PaymentSuppliers} from '../../../../../shared/db-models/payments';

@Component({
  selector: 'app-edit-currency-modal',
  templateUrl: './edit-currency-modal.component.html',
})
export class EditCurrencyModalComponent implements OnInit {
  isLoading = true;
  isSubmitting: boolean;

  @Input() currencyId: string;

  currency: DbCurrencyModel;
  stripeEnumValue = PaymentSuppliers.Stripe;

  paymentSuppliers: any[];

  constructor(
    public modal: NgbActiveModal,
    private storesService: StoresService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.paymentSuppliers = [];
    // tslint:disable-next-line:forin
    for (const currMember in PaymentSuppliers) {
      const currMemberInt = parseInt(currMember, 10);
      const isValueProperty = currMemberInt >= 0;
      if (isValueProperty) {
        this.paymentSuppliers.push({
          name: PaymentSuppliers[currMember],
          value: currMemberInt,
        });
      }
    }

    // Load the currency
    if (!this.currencyId) {
      this.currency = new DbCurrencyModel();

      this.isLoading = false;
    } else {
      this.loadCurrency();
    }
  }

  private loadCurrency(): void {
    this.storesService.getCurrencyById(this.currencyId).subscribe(
      (response) => {
        const data = response.data();
        if (!response.exists || !data) {
          this.toastr.error("Can't load currency.");
          this.modal.dismiss();
        } else {
          this.currency = data;
          this.currency.id = this.currencyId;
          this.isLoading = false;
        }
      },
      () => {
        // TODO: Show Error
        this.modal.dismiss();
      }
    );
  }

  public save(f: NgForm): void {
    if (f.invalid) {
      TwoRaffleHelpers.markFormInvalidAndFocus(f);
    } else {
      this.isSubmitting = true;
      f.form.disable();

      this.storesService
        .updateCurrency(this.currency)
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
            f.form.enable();
          })
        )
        .subscribe(
          () => {
            this.toastr.success('Action was completed successfully');
            this.modal.close();
          },
          (err) => {
            this.toastr.error('Failed to save currency. ' + err.message);
          }
        );
    }
  }

  public delete(): void {}
}
