import {Component, OnInit} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {UsersService} from './services/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isLoading = true;
  connectedUser;

  constructor(private usersService: UsersService) {
    this.connectedUser = this.usersService.getConnectedUserSync();
  }

  ngOnInit(): void {
    this.usersService
      .getConnectedUser()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((user) => {
        this.connectedUser = user;
      });
  }
}
