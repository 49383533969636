import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {SMSData, SMSProvider} from '../../../../shared/db-models/sms';
import {Timestamp} from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root',
})
export class SmsService {
  sendSmsInBulk(
    phoneNumbers: string[],
    smsMessage: string,
    time: Date,
    provider: SMSProvider,
    selectedStoresIds: string[],
    selectedSessionsIds: string[],
    defaultCountryCode: string,
    isTest: boolean
  ) {
    return from(
      this.firestore.collection<SMSData>('sms').add({
        phoneNumbers,
        sendWhen: Timestamp.fromDate(time),
        smsBody: smsMessage,
        wasSent: false,
        provider: provider,
        selectedStoresIds,
        selectedSessionsIds,
        defaultCountryCode,
        isTest,
      })
    );
  }

  getAll() {
    return this.firestore.collection<SMSData>('sms').valueChanges();
  }

  constructor(private firestore: AngularFirestore) {}
}
