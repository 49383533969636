import {Client} from '../../../shared/utilities/type-helpers';
import {environment as env} from './environment.live';
const client: Client = {clientType: 'claro'};

export const environment = {
  ...env,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyCsjR5bmDGAlXqB-IsS38bh7wHTGFbU4A4',
    authDomain: 'claro-staging-233e2.firebaseapp.com',
    databaseURL: 'https://claro-staging-233e2-default-rtdb.firebaseio.com',
    projectId: 'claro-staging-233e2',
    storageBucket: 'claro-staging-233e2.appspot.com',
    messagingSenderId: '895840884373',
    appId: '1:895840884373:web:1ad7c8e2ef2a1b6369ed9c',
    measurementId: 'G-T845XEL3P1',
  },
  client,
};
