<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="modal-header">
    <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
      Close
    </button>
    <h4 class="modal-title text-center" id="modal-title">
      {{ !currencyId ? 'Create New Currency' : 'Edit Currency' }}
    </h4>
  </div>
  <div class="modal-body">
    <form name="storeForm" #storeForm="ngForm">
      <div class="form-row justify-content-center">
        <div class="col-10">
          <div class="form-group">
            <label for="name">Name*</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              placeholder="Name"
              autocomplete="off"
              [(ngModel)]="currency.name"
              required
              maxlength="100"
            />
          </div>

          <div class="form-group">
            <label for="displayName">Display Name*</label>
            <input
              type="text"
              class="form-control"
              id="displayName"
              name="displayName"
              placeholder="Display Name"
              autocomplete="off"
              [(ngModel)]="currency.displayName"
              required
              maxlength="100"
            />
          </div>

          <div class="form-group">
            <label for="isoCode">ISO Code*</label>
            <input
              type="text"
              class="form-control"
              id="isoCode"
              name="isoCode"
              placeholder="ISO Code"
              autocomplete="off"
              [(ngModel)]="currency.isoCode"
              required
              maxlength="3"
            />
          </div>

          <div class="form-group">
            <label for="flagClass">Flag Css Class*</label>
            <input
              type="text"
              class="form-control"
              id="flagClass"
              name="flagClass"
              placeholder="Flag Css Class"
              autocomplete="off"
              [(ngModel)]="currency.flagClass"
              required
            />
          </div>

          <div class="form-group">
            <label for="paymentSupplier">Payment Supplier*</label>
            <ng-select
              id="paymentSupplier"
              name="paymentSupplier"
              placeholder="Payment Supplier"
              [items]="paymentSuppliers"
              bindLabel="name"
              bindValue="value"
              [(ngModel)]="currency.paymentSupplier"
              autocomplete="off"
              required
              [clearable]="false"
            >
            </ng-select>
          </div>

          <div class="form-group" *ngIf="currency.paymentSupplier === stripeEnumValue">
            <label for="multiplyByAmountOnCheckout"
              >Multiply By Amount On Checkout.<br />Dollar is 100 cents. so for USD it will be
              multiple by 100</label
            >
            <input
              type="number"
              class="form-control"
              id="multiplyByAmountOnCheckout"
              name="multiplyByAmountOnCheckout"
              placeholder="Multiply By Amount On Checkout"
              autocomplete="off"
              required
              [(ngModel)]="currency.multiplyByAmountOnCheckout"
              pattern="^[0-9]*$"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-yellow w-132"
      (click)="save(storeForm)"
      [disabled]="isSubmitting"
    >
      Save
    </button>
  </div>
</ng-container>
