export const currentYearString = new Date().getFullYear().toString();
export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
export const delay = async <T>(ms: number, value: T): Promise<T> => {
  await sleep(ms);
  return value;
};
export const throwErrorAfterDelay = async <T extends Error>(
  ms: number,
  value: T
): Promise<never> => {
  await sleep(ms);
  throw value;
};
