<div class="modal-header">
  <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
    Close
  </button>
  <h4 class="modal-title text-center" id="modal-title">{{ title ? title : 'Confirm Action' }}</h4>
</div>
<div class="modal-body">
  <h3>{{ text ? text : 'Are you sure you want to perform this action?' }}</h3>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-yellow w-132" (click)="confirm()">Confirm</button>
  <button type="button" class="btn btn-danger w-132" (click)="modal.dismiss()">Cancel</button>
</div>
