import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'terrific'};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyALgAqHtZqifUrXNJPtRQdFHnkVbecUFuQ',
    authDomain: 'terrific-live.firebaseapp.com',
    projectId: 'terrific-live',
    storageBucket: 'terrific-live.appspot.com',
    messagingSenderId: '23099111493',
    appId: '1:23099111493:web:43b245dd93c97252610b2c',
    measurementId: 'G-RK6B7C4WT5',
  },
  useEmulators: false,
  maxUplodedImageSize: 5000001,
  allowImageExt: '.jpg,.png,.jpeg',
  featureFlags: {longPolling: false},
  client,
};
