import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize} from 'rxjs/operators';
import {
  ListViewFilters,
  ListViewResults,
  ListViewSettings,
  ListViewTableColumn,
  ListViewTableColumnType,
} from '../../interfaces/list-view-models';
import {ServerResponse} from '../../interfaces/server-response';
import {StoresService} from 'src/app/services/stores.service';

@Directive({selector: 'ng-template[appActionsContent]'})
export class ActionContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'ng-template[appItemActionsContent]'})
export class ItemActionsContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'ng-template[appExpandContent]'})
export class ExpandContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
})
export class ListViewComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  ListViewTableColumnType = ListViewTableColumnType;

  @Input() public settings: ListViewSettings;

  actionsContentTpl: ActionContentDirective | null;
  @ContentChildren(ActionContentDirective, {descendants: false})
  actionsContentTpls: QueryList<ActionContentDirective>;

  itemActionsContentTpl: ItemActionsContentDirective | null;
  @ContentChildren(ItemActionsContentDirective, {descendants: false})
  itemActionsContentTpls: QueryList<ItemActionsContentDirective>;

  expandContentTpl: ExpandContentDirective | null;
  @ContentChildren(ExpandContentDirective, {descendants: false})
  expandContentTpls: QueryList<ExpandContentDirective>;

  isLoading = true;

  isGalleryMode: boolean;
  searchTerm = '';

  pageIndex = 0;
  pageCount: number;
  pages: number[] = [];

  sortColumn: string;
  sortDescending: boolean;

  data: any[];
  private storesService = inject(StoresService);
  private subsCleanup: Subscription[] = [];

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  storesIdsThatHasIntegration: Array<string>;

  constructor() {}

  ngAfterContentChecked(): void {
    this.actionsContentTpl = this.actionsContentTpls.first;
    this.itemActionsContentTpl = this.itemActionsContentTpls.first;
    this.expandContentTpl = this.expandContentTpls.first;
  }

  ngOnInit(): void {
    this.storesIdsThatHasIntegration = [];
    // Default Values
    if (this.settings.allowCreate === undefined) {
      this.settings.allowCreate = true;
    }

    if (this.settings.allowEdit === undefined) {
      this.settings.allowEdit = true;
    }

    if (this.settings.allowGalleryMode === undefined) {
      this.settings.allowGalleryMode = true;
    }

    if (this.settings.allowGalleryMode && this.settings.gallerySettings === undefined) {
      console.error('Gallery mode is enabled but no gallery settings were provided');
    }

    if (this.settings.allowTableMode === undefined) {
      this.settings.allowTableMode = true;
    }

    if (this.settings.allowTableMode && this.settings.tableSettings === undefined) {
      console.error('Table mode is enabled but no table settings were provided');
    }

    if (this.settings.searchPlaceholder === undefined) {
      this.settings.searchPlaceholder = 'Enter search text';
    }

    // Logic
    this.isGalleryMode = !!(this.settings?.allowGalleryMode && this.settings?.isGalleryDefault);
    this.sortColumn = this.settings.defaultSortColumn;
    this.sortDescending = this.settings.defaultSortDirection === 'desc';
  }

  ngAfterViewInit(): void {
    // Init search logic
    if (!this.settings.hideSearch) {
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(() => {
          this.refreshData();
        });
    }

    // Load the data after the view is initialized
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const currSub of this.subsCleanup) {
      currSub.unsubscribe();
    }
  }

  private loadData(): void {
    this.isLoading = true;

    const filters = this.getFilters();
    this.getStoreIdsThatHaveIntegration();
    this.settings
      .getData(filters)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: ServerResponse<ListViewResults<any>>) => {
        if (!response.result) {
          // TODO: Show error
        } else {
          this.pageCount = Math.ceil(response.data.dataCount / filters.pageSize);
          this.data = response.data.data;
          this.pages = [];
          let startIndex;
          let endIndex;
          if (this.pageCount <= 10) {
            startIndex = 0;
            endIndex = this.pageCount - 1;
          } else if (this.pageIndex + 9 > this.pageCount - 1) {
            // If will pass end
            startIndex = this.pageCount - 10;
            endIndex = this.pageCount - 1;
          } else {
            startIndex = this.pageIndex;
            endIndex = this.pageIndex + 9;
          }

          for (let i = startIndex; i <= endIndex; i++) {
            this.pages.push(i);
          }
        }
      });
  }

  private getFilters(): ListViewFilters {
    return {
      pageIndex: this.pageIndex,
      pageSize: this.getPageSize(),
      searchTerm: this.searchTerm,
      sortDescending: this.sortDescending,
      sortColumn: this.sortColumn,
    };
  }

  private getPageSize(): number {
    let pageSize: number;

    if (this.isGalleryMode) {
      pageSize = this.settings.allowCreate ? 19 : 20;
    } else {
      pageSize = 20;
    }

    return pageSize;
  }

  public refreshData(): void {
    window.scroll(0, 0); // Scroll to top
    this.loadData();
  }

  public goToGallery(): void {
    if (this.settings.allowGalleryMode) {
      window.scroll(0, 0); // Scroll to top
      this.isGalleryMode = true;
    }
  }

  public goToTable(): void {
    if (this.settings.allowTableMode) {
      window.scroll(0, 0); // Scroll to top
      this.isGalleryMode = false;
    }
  }

  public sortByColumn(column: ListViewTableColumn): void {
    if (column.allowSort) {
      if (this.sortColumn === column.name) {
        this.sortDescending = !this.sortDescending;
      } else {
        this.sortColumn = column.name;
        this.sortDescending = false;
      }

      this.refreshData();
    }
  }

  public createItem(): void {
    if (this.settings.allowCreate && this.settings.create) {
      this.settings.create().subscribe((result: boolean) => {
        if (result) {
          this.refreshData();
        }
      });
    }
  }

  public editItem(item: any): any {
    if (this.settings.allowEdit && this.settings.edit) {
      this.settings.edit(item).subscribe((result: boolean) => {
        if (result) {
          this.refreshData();
        }
      });
    }

    return false;
  }

  getStoreIdsThatHaveIntegration() {
    const subscriber = this.storesService
      .getStoreIdsThatHaveIntegration()
      .subscribe((storesIds) => {
        this.storesIdsThatHasIntegration = storesIds;
      });
    this.subsCleanup.push(subscriber);
  }

  public syncIntergrationData(storeDataitem: any): void {
    if (this.settings.allowEdit && this.settings.edit) {
      const lastKnownSync = storeDataitem.lastSyncTime
        ? storeDataitem.lastSyncTime + 0
        : storeDataitem.lastSyncTime; // copy data
      try {
        this.settings.syncIntegrationData(storeDataitem.id);
        storeDataitem.lastSyncTime = new Date().getTime();
      } catch (err) {
        storeDataitem.lastSyncTime = lastKnownSync;
        console.error(err);
      }
    }
  }

  public getSyncIconTooltip(storeDataitem: any) {
    const formattedDate = storeDataitem.lastSyncTime
      ? new Date(storeDataitem.lastSyncTime).toLocaleString()
      : 'None';
    return 'Sync Integration\n (Last Sync Time: ' + formattedDate + ')';
  }

  public getExpandColspan(): number {
    let colspan = this.settings?.tableSettings?.columns?.length ?? 0 + 1;

    if (this.settings.showActiveState) {
      colspan++;
    }

    if (this.settings.allowEdit || this.itemActionsContentTpl) {
      colspan++;
    }

    return colspan;
  }

  public expandItem(item: any): void {
    item.expanded = !item.expanded;
    item.loaded = true;
  }

  public nextPage(): void {
    if (this.pageIndex < this.pageCount - 1) {
      this.goToPage(this.pageIndex + 1);
    }
  }

  public prevPage(): void {
    if (this.pageIndex > 0) {
      this.goToPage(this.pageIndex - 1);
    }
  }

  public goToPage(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.refreshData();
  }

  public formatDefaultText(text: any, column: ListViewTableColumn): string {
    if (!text && text !== 0) {
      return column.emptyText ? column.emptyText : '';
    }

    return text;
  }

  public formatBoolean(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  public formatDateTime(dateTime: any, column: ListViewTableColumn): string {
    if (!dateTime) {
      return column.emptyText ? column.emptyText : '';
    }

    return '';
  }

  public formatDate(date: any, column: ListViewTableColumn): string {
    if (!date) {
      return column.emptyText ? column.emptyText : '';
    }

    return '';
  }

  public getItemColumnLink(item: any, column: ListViewTableColumn): any {
    if (column.linkFunc) {
      return column.linkFunc(item);
    }

    return null;
  }

  public getItemGalleryLink(item: any): any {
    if (this.settings?.gallerySettings?.linkFunc) {
      return this.settings.gallerySettings.linkFunc(item);
    }

    return null;
  }
}
