import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  @Input() title: string;
  @Input() text: string;

  constructor(public modal: NgbActiveModal) {}

  public confirm(): void {
    this.modal.close(true);
  }
}
