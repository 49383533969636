import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../../services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public isSubmitting: boolean = false;

  public email: string = '';
  public password: string = '';
  public rememberMe: boolean = false;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.usersService.getConnectedUser().subscribe((user) => {
      if (user != null) {
        this.router.navigate(['/home']);
      }
    });
  }

  /**
   * Sign in with Google or Facebook
   * @param f
   * @param provider
   */
  public signInWithProvider(f: NgForm, provider: string): void {
    this.isSubmitting = true;
    f.form.disable();
    this.usersService
      .signinWithProvider(provider)
      .then(async (credential) => {
        const user = credential.user;
        if (user) {
          if (!user.phoneNumber) {
            this.toastr.error('User has no valid phone number');
          } else {
            const isAdmin = await this.usersService.isUserAdmin(user);
            if (!isAdmin) {
              this.toastr.error('User is not an admin');
            } else {
              this.usersService.updateConnectedUser(user);
              this.router.navigate(['/home']);
            }
          }
        }
      })
      .catch((error) => {
        // The provider's credential:
        alert(error.message);
        this.usersService.signOut().then(null, null);
      })
      .finally(() => {
        f.form.enable();
        this.isSubmitting = false;
      });
  }

  public login(f: NgForm): void {
    if (f.valid) {
      this.isSubmitting = true;
      f.form.disable();

      this.usersService
        .signInWithEmail(this.email, this.password)
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
            f.form.enable();
          })
        )
        .subscribe(
          (credential) => {
            // this.isFormSubmitted = false;
            const user = credential.user;
            if (user?.phoneNumber) {
              this.usersService.updateConnectedUser(user);
              this.router.navigate(['/home']);
            } else {
              this.toastr.error('User has no valid phone number');
            }
          },
          (error) => {
            const errorCode = error.code;
            if (errorCode === 'auth/wrong-password') {
              this.toastr.error('Invalid password');
            }
            if (errorCode === 'auth/invalid-email') {
              this.toastr.error('Invalid email');
            }
            if (errorCode === 'auth/user-not-found') {
              this.toastr.error(
                "Sorry, we couldn't find your account. Please try again with a different email"
              );
              console.error(error.message);
            }
            if (errorCode === 'auth/user-disabled') {
              this.toastr.error('User is disabled');
              console.error(error.message);
            }
          }
        );
    }
  }
}
